.picklist-filter .product-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
  }
  
  .picklist-filter .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
  }
  
  .picklist-filter .product-item .product-list-detail {
    flex: 1 1 0;
  }
  
  .picklist-filter .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .picklist-filter .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  
  .picklist-filter .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
  }
  .picklist-filter .p-picklist .p-picklist-header {
    padding: 0.6rem 1.25rem !important;
  }
  .picklist-filter .p-picklist .p-picklist-list-wrapper {
    width: 24%;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.4rem 1rem !important;
    font-size: 14px;
  }
  .p-picklist .p-picklist-list .p-picklist-item .field-checkbox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 576px) {
    .picklist-filter .product-item {
      flex-wrap: wrap;
    }
  
    .picklist-filter .product-item .image-container {
      width: 100%;
      text-align: center;
    }
  
    .picklist-filter .product-item img {
      margin: 0 0 1rem 0;
      width: 100px;
    }
  }
  