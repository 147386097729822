.redCls,
.personalCls{
    color:black;
}
.redCls{
    background-color:#f44336;
    color: black !important;
}
.personalCls{
    background-color:rgb(255,120,120);
    color: black !important;
}