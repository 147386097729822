.carousel-part .carousel-item .carousel-item-content {
    margin    : 0;
    text-align: center;
    padding   : 5px 10px;
}

.carousel-item-content {
    position       : relative;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-around;
    text-align     : center;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right : 0.3rem;
    margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width        : 0.7rem;
    height       : 0.7rem;
    padding      : 0 !important;
    border-radius: 50% !important;
}

.sla-mainbox {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    padding        : 20px 5px;
    height         : auto;
    position       : relative;
    background     : rgba(0, 0, 0, 0.05);
}

.sla-mainbox.withotheroption {
    justify-content: space-between;
}

.sla-text {
    position      : absolute;
    bottom        : 15px;
    right         : 0;
    left          : 0;
    margin        : 0 auto;
    margin-bottom : 0px;
    color         : #1976d2;
    font-family   : 'Lato', sans-serif !important;
    font-weight   : 600;
    font-size     : 14px;
    letter-spacing: 0.04rem;
}

.carousel-data {
    display        : flex;
    justify-content: space-between;
    width          : 100%;
}

.carousel-box {
    margin : 5px;
    color  : #000;
    padding: 5px;
    width  : auto;
}


.carousel-box p {
    margin-bottom : 0px;
    color         : #1976d2;
    font-family   : 'Lato', sans-serif !important;
    font-weight   : 600;
    font-size     : 14px;
    letter-spacing: 0.04rem;
}

.carousel-box span {
    font-size  : 38px;
    line-height: 42px;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width : 1rem;
    height: 1rem;
    margin: 0.4rem;
}

.carousel-part,
.p-carousel,
.p-carousel-content,
.p-carousel-container {
    width : 100%;
    height: 100%;
}

.firstpart-carousel {
    display        : flex;
    justify-content: space-between;
    width          : 100%;
}

.carousel-heading {
    font-size    : 16px;
    font-weight  : 600;
    margin-bottom: 5px;
}

.carousel-box span sub {
    bottom     : 0;
    font-size  : 16px;
    margin-left: 7px;
}

.sla-mainbox #service-level-undefined .text-group text {
    fill: rgb(0, 0, 0) !important;
}

.carouselbgimg {
    background-image   : url("../../../assests/images/ewfm-bg.jpg");
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : 100%;
    height             : 200px;
    width              : 200px;
}

.barchart-box {
    display: block;
    width  : 100%;
    height : 100%;
}

.barchart-box .highcharts-legend-item,
.barchart-box .highcharts-legend,
.barchart-box .highcharts-axis-title {
    display: none;
}

.carousel-item.email-age .carousel-heading,
.carousel-item.chat-age .carousel-heading {
    margin-bottom: 15px;
}

.p-link:focus {
    box-shadow: none !important;
}