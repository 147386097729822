.headcounthistory-list{
  float: left;
  width: 100%;
}
.alert-filter-button-hcp {
  float: right;
  width: 200px;
}
.headcounthistorylist-box .p-datatable-scrollable .p-datatable-wrapper {
    height: 428px;
  }
  .viewall-alert-butn-box {
    text-align     : right;
    margin-bottom  : 20px;
    /* display        : flex;
    align-items    : center;
    justify-content: space-between; */
    width: 100%;
    float: left;
  
  }
  .white-board.hcp {
    min-height: 594px;
  }
  .HCPAlertFilter .alertsavefilters {
    position  : absolute;
    background: #fff;
    z-index   : 999;
    opacity   : 0;
    display   : none;
    left      : -152px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
    width     : 190px;
    top       : 39px;
    border    : 1px solid #ddd;
    height    : 170px;
    overflow  : auto;
  }
  
  .alertsavefilters ul {
    margin : 0;
    padding: 4px 0;
  }
  
  .alertsavefilters ul li {
    margin        : 0;
    padding       : 3px 10px;
    position      : relative;
    cursor        : pointer;
    font-size     : 14px;
    font-weight   : 500;
    letter-spacing: 0.02rem;
    color         : #000;
    font-family   : "Lato", sans-serif;
  }
  .viewall-search-box-hcp {
    width: 390px;
    float: left;
    margin: 0px 40px 0px 0;
}
  @media (min-width: 1920px) {
    .headcounthistorylist-box .p-datatable-scrollable .p-datatable-wrapper {
      height: 675px;
    }
  }

.css-1ujsas3{
  min-width : 0px !important;
}