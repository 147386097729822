.ENDED {
  background-color: rgb(255, 60, 60);
  color: black !important;
}
.ONHOLD {
  background-color: rgb(142, 169, 219);
  color: black !important;
}
.CONNECTED {
  background-color: #4caf50;
  color: black !important;
}
.CONNECTING {
  background-color: #64ffda;
  color: black;
}
.MISSED {
  background-color: rgb(255, 75, 75);
  color: black !important;
}
.EMPTY {
  background: #81c784;
  color: black !important;
}
.Callback {
  background-color: rgb(146, 208, 80);
  color: black !important;
}
.Chat {
  background-color: rgb(158, 255, 150);
  color: black;
}
.Email {
  background-color: rgb(66, 255, 51);
  color: black;
}
.Chat_and_Email {
  background-color: rgb(14, 196, 0);
  color: black !important;
}
.Outbound {
  background-color: rgb(153, 153, 255);
  color: black !important;
}
.WOCC {
  background-color: rgb(255, 0, 102);
  color: black !important;
}
.Meeting_Admin {
  background-color: rgb(0, 176, 240);
  color: black !important;
}
.Coaching {
  background-color: rgb(217, 0, 102);
  color: black !important;
}
.Lunch {
  background-color: rgb(255, 225, 0);
  color: black;
}
.Special_Task {
  background-color: rgb(189, 215, 238);
  color: black !important;
}
.Training {
  background-color: rgb(0, 114, 192);
  color: black !important;
}
.Break {
  background-color: #76ff03;
}
.Personal {
  background-color: rgb(255, 217, 102);
  color: black;
}
.System_Issues {
  background-color: #f44336;
}
.QA {
  background-color: rgb(51, 126, 195);
}
.Chat_Wrapup {
  background-color: rgb(211, 127, 145);
}
.READY {
  background: #81c784;
  color: black !important;
}