.AgentStatusList table tr th .p-column-header-content {
  display: block !important;
}

.AgentStatusList .p-datatable .p-datatable-tbody > tr.p-rowgroup-header > td {
  text-align: left !important;
  padding: 4px 1rem;
  background: #e9f4ff;
  justify-content: left !important;
}
.AgentStatusList {
  width: 100%;
  position: relative;
}
.AgentStatusList .p-datatable-wrapper {
  width: 100%;
}
.AgentStatusList .p-datatable .p-datatable-thead > tr > th {
  /* width: 15%; */
  padding: 6px 1rem;
  border: 0;
  font-weight: 600;
  letter-spacing: 0.02rem;
  text-align: center !important;
  justify-content: center;
}

.AgentStatusList .p-datatable .p-datatable-tbody > tr > td {
  font-size: 12px;
  padding: 2px 1rem !important;
  border: 0;
}
.p-rowgroup-header-name {
  font-size: 12px;
  font-weight: 600;
}

.AgentStatusList .p-datatable .p-datatable-tbody > tr {
  font-size: 12px;
}
.agentsfullname {
  text-align: left;
  font-size: 13px !important;
}
.p-column-title {
  font-size: 14px;
}
.AgentStatusList .pi {
  font-size: 0.6rem;
}
.AgentStatusList .p-datatable .p-datatable-tbody > tr button {
  margin-right: 8px;
}
.p-row-odd {
  background: rgba(0, 0, 0, 0.04) !important;
}
.AgentStatusList .p-datatable-table .p-datatable-thead {
  position: sticky;
  z-index: 1;
  top: -1px;
}

.AgentWidget {
  width: 100%;
  float: right;
}
.AgentStatusList .p-datatable .p-datatable-tbody > tr > td > div {
  font-size: 12px;
  width: 100%;
}
.AgentStatusList .p-datatable.p-component {
  overflow: auto;
}
.AgentStatusList .p-datatable .p-datatable-table .p-datatable-thead > tr {
  border-bottom: 1px solid #ddd;
}

/* .AgentStatusList .p-datatable-wrapper {  
  height: var(--AS-DynamicHeight);
  overflow: auto;
} */
