.manage-workgrup {
    margin-top   : 50px;
    margin-bottom: 30px;
}

.group.editqueueform .rightsidebutn {
    margin-top: 0 !important;
}

.manage-workgrup .p-form .textbox {
    width: 55% !important;
}