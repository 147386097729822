.cellSuccess {
  background-color: #4caf50;
  width: 100%;
}

.cellWarning {
  background-color: #ff9800;
  width: 100%;
}

.cellError {
  background-color: #f44336;
  width: 100%;
}
.tzone span {
  font-size: 8px;
  font-weight: 500;
  color: gray;
}
.AgentMarketlist .p-datatable-scrollable .p-datatable-tbody > tr > td {
  justify-content: center;
}
.AgentMarketlist .p-datatable-scrollable .p-datatable-tbody > tr:hover {
  background: #e9ecef;
  color: #495057;
}
.AgentMarketlist .p-treetable .p-treetable-scrollable-header-table {
  width: 100% !important;
}
.AgentMarketlist .p-treetable-scrollable-body-table {
  width: 100% !important;
}
