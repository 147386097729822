.p-datatable-tbody
  tr
  > td
  > .p-datatable.p-component
  > .p-datatable-wrapper
  > table
  > .p-datatable-thead {
  display: none;
}

.AgentMarketlist .p-datatable .p-column-header-content {
  display: block;
}

.AgentMarketlist
  .p-datatable-table
  > .p-datatable-tbody
  > .p-datatable-row-expansion
  > td {
  padding: 0 !important;
}

.AgentMarketlist .p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 1rem !important;
  font-size: 14px;
  text-align: center !important;
}
/* .AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(2),
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(2) {
  width: 15%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(2) {
  justify-content: left;
}
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(1),
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(1) {
  width: 5% !important;
} */

.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center !important;
}

/* .AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(9),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(9) {
  width: 9.5%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(8),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(8) {
  width: 9.5%;
}

.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(3),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(3) {
  width: 9.3%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(6),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(6) {
  width: 11.5%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(4),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(4) {
  width: 10.2%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(5),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(5) {
  width: 14.2%;
}
.AgentMarketlist
  .p-datatable
  .p-datatable-tbody
  tr.p-datatable-row-expansion
  td
  table
  .p-datatable-tbody
  tr
  td:nth-child(7),
.AgentMarketlist .p-datatable .p-datatable-thead > tr > th:nth-child(7) {
  width: 9.5%;
} */
.AgentMarketlist .p-datatable.p-component {
  overflow: auto;
  width: 100%;
}

/* .AgentMarketlist .p-datatable-wrapper {
  max-height: var(--AM-DynamicHeight);
  overflow: auto;
} */

.AgentMarketlist .p-datatable-table .p-datatable-thead {
  position: sticky;
  z-index: 1;
  top: -1px;
}
.AgentMarketlist .p-treetable .p-treetable-thead > tr > th {
  padding: 7px 1rem !important;
  font-weight: 600;
  letter-spacing: 0.02rem;
  height: 40px;
  justify-content: center;
  border: 0;
}
.tzone {
  display: inline-block;
  vertical-align: middle;
}
.p-treetable .p-treetable-tbody > tr > td {
  padding: 2px 5px !important;
  font-size: 13px;
  text-align: center !important;
  border: 0;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #343a40;
}
.p-treetable .p-treetable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.04) !important;
}
.p-treetable .p-treetable-tbody > tr > td:nth-child(2) {
  text-align: left !important;
}
.tzone.details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.AgentMarketlist .p-treetable .p-treetable-thead {
  position: sticky;
  z-index: 1;
  top: -1px;
}
.AgentMarketlist .p-treetable .p-sortable-column .p-sortable-column-icon,
.EmailCaseAgelist .p-treetable .p-sortable-column .p-sortable-column-icon {
  font-size: 0.6rem;
}
.AgentMarketlist .p-treetable .p-treetable-thead > tr {
  border-bottom: 1px solid #ddd;
}
