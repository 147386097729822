ul {
  list-style-type: none;
}

.privilege-text {
  margin  : 0;
  padding : 0;
  position: relative;
  height  : 345px;
  overflow: auto;
}

.caret {
  cursor             : pointer;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

.caret::before {
  content     : "\23F5";
  color       : black;
  display     : inline-block;
  margin-right: 6px;
  position    : absolute;
  margin-left : -20px;
}

.caret-down::before {
  transform        : rotate(90deg);
  -ms-transform    : rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.nested {
  display: none;
}

.active {
  display: block;
}

.nested.active {
  padding-left: 25px;
  color       : #000;
}

.privilege-text ul li>span {
  font-size  : 16px;
  font-weight: 600;
}

.privilege-text ul li .MuiCheckbox-root {
  padding: 1px 8px 1px;
}

.privilege-text ul li {
  position     : relative;
  padding      : 4px 35px;
  border-radius: 6px;
  width        : 100%;
  margin-right : 0;
}

.privilege-text ul li span .MuiSvgIcon-root {
  position         : absolute;
  left             : 5px;
  top              : 50%;
  transform        : translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform   : translateY(-50%);
  width            : 1.7rem;
  height           : 1.7rem;
  border           : 0 none;
  background       : transparent;
  border-radius    : 50%;
  transition       : background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.privilege-text ul li span .MuiSvgIcon-root:focus,
.privilege-text ul li span .MuiSvgIcon-root:hover,
.privilege-text ul li span .MuiSvgIcon-root:active {
  outline       : 0 none;
  outline-offset: 0;
  box-shadow    : 0 0 0 0.2rem #c7d2fe;
}

.treecheckbox>svg {
  position         : inherit !important;
  left             : inherit !important;
  top              : inherit !important;
  transform        : inherit !important;
  -webkit-transform: inherit !important;
  -moz-transform   : inherit !important;
  width            : 2rem;
  height           : 2rem;
}

.privilege-text>ul {
  position: relative;
}

.privilege-text+ul+li+label>span.MuiFormControlLabel-label {
  font-size  : 16px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.privilege-text ul li:hover {
  background: #e9ecef;
  color     : #495057;
}

.privilege-text ul li span.MuiFormControlLabel-label {
  font-family   : "Mulish", sans-serif !important;
  font-weight   : 400;
  letter-spacing: 0.03em;
  font-size     : 15px;
}

.privilege-text ul.nested.active li:hover {
  background: #e9ecef;
  color     : #495057;
}

.privilege-text ul.nested.active li {
  padding: 3px 35px;
  width  : 96% !important;
}

@media (min-width: 1920px) {
  .privilege-text {
    height: 565px;
  }
}