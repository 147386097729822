.p-dialog {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;


  .p-dialog-header {
    background: white;
    padding   : 0.5rem 0.75rem !important;
  }



  .p-dialog-footer {
    border-top: none;
    padding   : 0.5rem 0.75rem !important;
  }
}

.p-dialog-title {
  font-size: 16px !important;
}