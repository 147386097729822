*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
}

a {
  cursor                      : pointer;
  -webkit-text-decoration-skip: objects;
  text-decoration             : none;
  -webkit-transition          : all 0.2s ease-out 0s;
  -moz-transition             : all 0.2s ease-out 0s;
  -o-transition               : all 0.2s ease-out 0s;
  transition                  : all 0.2s ease-out 0s;
}

*:hover {
  -webkit-text-decoration-skip: objects;
  text-decoration             : none;
  -webkit-transition          : all 0.2s ease-out 0s;
  -moz-transition             : all 0.2s ease-out 0s;
  -o-transition               : all 0.2s ease-out 0s;
  transition                  : all 0.2s ease-out 0s;
}

ul {
  margin : 0;
  padding: 0;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Catamaran", sans-serif !important;
}

h1 {
  font-size  : 52px;
  color      : #fff;
  font-weight: 700;
  font-family: "Cairo", sans-serif;
}

h2 {
  font-size    : 26px;
  font-weight  : 600;
  line-height  : 1.2;
  color        : #303030;
  margin-bottom: 20px;
}

h3 {
  font-size    : 20px;
  font-weight  : 600;
  line-height  : 1.2;
  color        : #303030;
  margin-bottom: 20px;
}

h4 {
  font-size    : 18px;
  font-weight  : 700 !important;
  line-height  : 1.2;
  color        : #303030;
  margin-bottom: 20px;
}

h5 {
  font-size    : 16px;
  font-weight  : 600;
  line-height  : 1.2;
  color        : #303030;
  margin-bottom: 20px;
}

Button,
.MuiButton-root.MuiButtonBase-root {
  font-weight   : 400 !important;
  font-family   : "Catamaran", sans-serif !important;
  letter-spacing: 0.04em !important;
  text-transform: capitalize !important;
  font-size     : 16px !important;
  padding       : 8px 11px !important;
  border-radius : 3px !important;
}

body {
  margin: 0;

  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight            : 400;
  line-height            : 1.5;
  color                  : #303030;
  overflow-y             : auto;
  overflow-x             : hidden;
  width                  : 100%;
  height                 : 100vh;
  background             : #eff2fe !important;
  font-family            : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.container {
  padding      : 20px 50px;
  width        : 100%;
  /* box-shadow:0px 0px 4px #888;*/
  margin       : 0 auto;
  height       : 100%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: auto;
  min-width: 550px;
}

::-webkit-scrollbar {
  width : 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background   : rgba(0, 0, 0, 0.2);
}

body.black-theme::-webkit-scrollbar-thumb,
body.black-theme::-moz-scrollbar-thumb {
  background-color: #7f7f7f !important;
}

body.black-theme::-webkit-scrollbar-thumb,
body.black-theme::-moz-scrollbar-thumb {
  background-color: #7f7f7f !important;
}

body.black-theme::-webkit-scrollbar-thumb,
body.black-theme::-moz-scrollbar-thumb {
  background-color: #7f7f7f !important;
}

::-moz-scrollbar {
  width : 7px;
  height: 7px;
}

::-moz-scrollbar-thumb {
  border-radius: 20px;
  background   : #f8fafc;
  border       : 4px solid rgba(0, 0, 0, 0.3);
}

.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogActions-root {
  padding: 10px 16px;
}

.css-12xvzhw-MuiAutocomplete-root {
  width: 100% !important;
}

.common-iconbutton Button {
  padding         : 8px;
  background-color: #1976d2;
  width           : auto;
  text-align      : left !important;
  color           : #fff;
  border-radius   : 50%;
  min-width       : inherit;
  border          : 1px solid transparent;
}

.common-iconbutton Button:hover,
.common-iconbutton.timeoff-butn Button:hover {
  border          : 1px solid #1976d2;
  background-color: #1565c0;
  box-shadow      : 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding    : 3px 7px !important;
  font-family: "Mulish", sans-serif !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 15.5px 15px !important;
}

table tr th,
table tr td {
  text-align: center !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin   : 0.3rem 0 0 0 !important;
  font-size: 15px !important;
}

.MuiInput-input,
.MuiOutlinedInput-input {
  padding    : 11px 15px 12px !important;
  font-family: "Mulish", sans-serif !important;
}

.MuiInputLabel-root {
  line-height  : 18px !important;
  border-radius: 3px !important;
  font-family  : "Mulish", sans-serif !important;
}

.MuiOutlinedInput-root {
  line-height  : 20px !important;
  border-radius: 3px !important;
}

.MuiTablePagination-toolbar {
  min-height: 40px !important;
}

.white-board {
  background        : #fff;
  padding           : 20px;
  border-radius     : 4px 4px 0 0;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition        : box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow        : 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  position  : relative;
  min-height: 615px;
  height    : auto;
}

.breadcrumbox {
  padding-left: 22px;
  position    : absolute;
  background  : #1976d2;
  height      : 140px;
  width       : 100%;
  left        : 0;
  z-index     : -1;
  top         : 64px;
  padding-top : 20px;
  color       : #fff;
}

Button.MuiAutocomplete-clearIndicator,
Button.MuiAutocomplete-popupIndicator {
  padding: 4px !important;
}

.common-iconbutton button svg {
  font-size: 24px !important;
}

nav,
nav.MuiBreadcrumbs-root ol li p {
  font-family   : "Mulish", sans-serif !important;
  font-weight   : 400;
  font-size     : 14px !important;
  letter-spacing: 0.02rem !important;
}

.MuiAutocomplete-endAdornment {
  top: calc(50% - 16px) !important;
}

main {
  position: relative;
  width   : 100%;
  overflow: auto;
  height  : 100vh;
}

.breadcrumboxinner {
  height     : 80px;
  padding-top: 10px;
}

.MuiBreadcrumbs-root {
  margin-top: 30px !important;
}

.p-datepicker .p-datepicker-header {
  background   : none !important;
  border-bottom: none !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  border: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  border: none;
}

.hidden {
  display: none;
}

.p-sidebar .p-sidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
  padding      : 10px !important;
}

.p-sidebar .p-sidebar-content h2 {
  font-size    : 20px;
  font-weight  : 600;
  line-height  : 24px;
  margin-bottom: 15px;
}

.p-sidebar .p-sidebar-content {
  margin-top: -68px !important;
}

.rightsidebutn {
  margin-top: 30px;
  text-align: right;
}

.p-invalid .MuiOutlinedInput-notchedOutline {
  border-color: #dc3545;
}

.p-invalid label {
  color: #dc3545;
}

.searchinput {
  line-height  : 16px !important;
  border-radius: 15px;
  border-style : solid;
  border-width : 1px;
  border-color : rgba(0, 0, 0, 0.23);
  width        : 90%;
}

.searchinput:focus,
.searchinput:visited,
.searchinput:active {
  border-color: #1976d2;
}

.searchinput::after,
.searchinput::before {
  border: none !important;
}

.searchbutton-icn svg {
  width : 0.9em;
  height: 0.9em;
}

.searchbutton-icn {
  position     : absolute;
  left         : 1px;
  border-right : 1px solid #ddd;
  padding      : 10px 8px 2px 13px;
  top          : 1px;
  border-radius: 30px 0px 0px 30px;
  cursor       : pointer;
}

.searchinput input {
  padding: 9px 15px 9px !important;
}

.searchinput input {
  padding-left: 55px !important;
}

.p-sidebar-right {
  z-index: 1275 !important;
}

.sidebarright .p-sidebar-header {
  z-index: 9999;
}

.sidebarright .p-sidebar-content {
  margin-top: -75px;
}

.p-sidebar-right .p-sidebar {
  width: 35rem !important;
}

.p-sidebar-right .p-sidebar .p-sidebar-content .MuiDialogContent-dividers {
  border: none !important;
}

.MuiTooltip-popper {
  letter-spacing: 0.06rem;
}

.accountmenu .MuiList-root.MuiMenu-list {
  height: auto !important;
}

.bread-crums-box {
  margin-bottom: 15px;
  margin-top   : 45px;
}

img {
  border         : 0 none;
  text-decoration: none;
  outline        : unset;
}

.MuiInputLabel-root.MuiInputLabel-formControl {
  top: -2px;
}

.bread-crums-box ol li a {
  text-decoration: none !important;
  font-size      : 14px !important;
}

.bread-crums-box ol li a:hover {
  text-decoration: none !important;
  color          : rgb(25, 118, 210);
}

.search-box-common {
  position: relative;
}

.search-box-common .search-icon {
  position   : absolute;
  right      : 1px;
  border-left: 1px solid #ddd;
  padding    : 9px 10px 3px 10px;
  top        : 1px;
  cursor     : pointer;
}

.search-box-common .search-icon svg {
  width : 0.9em;
  height: 0.9em;
}

.search-box-common fieldset {
  border-radius: 15px;
}

.search-box-common .MuiOutlinedInput-input {
  padding    : 9px 55px 9px 15px !important;
  font-family: "Mulish", sans-serif !important;
}

.MuiDialog-container .MuiPaper-root h2 {
  padding       : 10px 20px;
  font-size     : 16px;
  letter-spacing: 0.03rem;
}

.MuiDialog-container .MuiPaper-root h2 button {
  right: 8px;
  top  : 4px;
}

.MuiDialogActions-root button {
  margin: 0;
}

.MuiDialogActions-root {
  padding: 8px 20px;
}

.MuiBreadcrumbs-separator {
  margin-left : 5px !important;
  margin-right: 5px !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  background: #eff2fe;
}

.p-treetable .p-treetable-thead>tr>th {
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.p-treetable .p-treetable-thead>tr>th {
  padding        : 7px 1rem !important;
  font-weight    : 600;
  letter-spacing : 0.02rem;
  height         : 40px;
  justify-content: center;
  border         : 0;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #ffffff;
  color     : #007bff;
}

.p-column-title {
  font-size: 14px;
}

.p-treetable .p-treetable-thead>tr {
  border-bottom: 1px solid #ddd;
}

.p-treetable .p-treetable-thead {
  position: sticky;
  z-index : 1;
  top     : -1px;
}

.ant-drawer-header {
  padding: 10px 20px !important;
}

.ant-drawer-close {
  position: absolute;
  right   : 0;
}

.ant-btn {
  height: auto !important;
}

.p-button {
  background: #1976d2 !important;
}

.footerbutn {
  position: absolute;
  bottom  : 5px;
  right   : 15px;
  margin  : 10px 0;
}

.footerbutn button {
  margin: 8px 6px 0;
  width : 90px;
}

.ant-row,
.ant-select {
  position: relative;
}

.ant-btn-primary {
  background-color: #1976d2;
}

.ant-btn-primary:hover {
  background-color: #1565c0 !important;
  border          : 1px solid #1976d2;
  box-shadow      : 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}


.p-datatable .p-column-header-content {
  justify-content: center;
}

.p-datatable .p-datatable-thead>tr>th {
  padding        : 10px !important;
  justify-content: center;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size      : 13.5px;
  padding        : 3px 5px !important;
  justify-content: center;
}

.p-datatable .p-datatable-tbody>tr>td .action-buttons a {
  margin: 0 5px;
}

.p-paginator {
  justify-content: end !important;
}

.p-sidebar .p-sidebar-header {
  padding: 10px 1.25rem !important;
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #64b5f6;
}

.highcharts-container {
  margin: 0 auto;
}

.privilege-text>ul {
  margin-bottom: 0;
}

.MuiTablePagination-toolbar p {
  margin-bottom: 0 !important;
}

.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
  background-color: inherit !important;
}

.tab-content.dna .no-recordfound {
  height         : 342px;
  background-size: 15%;
}

.text-popup {
  text-align: center;
  padding   : 20px 0;
  max-width : 350px;
  margin    : 0 auto;
}

button.secondary {
  background-color: rgba(0, 0, 0, 0.54) !important
}

button.secondary:hover {
  background-color: rgba(0, 0, 0, 0.64) !important;
}

.p-button.p-button-danger,
body.black-theme .p-button.p-button-danger {
  background: #c82333 !important;
}

.p-button.p-confirm-dialog-reject,
body.black-theme .p-button.p-confirm-dialog-reject {
  background: none !important;
}

.p-treetable .p-treetable-scrollable-footer {
  background: inherit !important;
}

.PrivatePickersYear-root button {
  line-height: 22px !important;
}

.p-datepicker-trigger {
  border-radius: 0 !important;
}

.p-radiobutton-box.p-focus .p-radiobutton-icon {
  transform : translateZ(0) scale(1);
  visibility: visible;
}

.p-radiobutton .p-radiobutton-box.p-focus {
  border-color: #1976d2;
  background  : #1976d2;
}

.p-tabview ul li.p-tabview-selected.p-highlight .p-tabview-nav-link {
  background  : #ffffff;
  border-color: #1976d2 !important;
  color       : #1976d2 !important;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.MuiSelect-select.MuiTablePagination-select {
  line-height: 24px;
}

.MuiSvgIcon-root {
  margin-right: 0 !important;
}

.p-inputtext.p-component {
  padding: 9px 12px 10px;
}

.p-dropdown .p-dropdown-clear-icon {

  right: 32px !important;
}

.ant-drawer-body {
  padding: 20px !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-color: #ffffff #ffffff #ffffff #ffffff !important;
  margin      : 0 0 0px 0 !important;
}

.p-button .p-badge {
  color           : #fff !important;
  background-color: #e53329 !important;
  height          : 18px !important;
  line-height     : 8px !important;
}

.p-badge {
  padding: 4px 7px !important;
}

.p-dialog-mask {
  z-index: 9999 !important;
}

.p-datepicker,
.p-dropdown-panel {
  z-index: 99999 !important;
}

@keyframes p-progress-spinner-color {

  0%,
  100% {
    stroke: #d62d20;
  }

  40% {
    stroke: #0057e7;
  }

  66% {
    stroke: #008744;
  }

  80%,
  90% {
    stroke: #ffa700;
  }
}

.ant-modal-close {
  padding      : 0 !important;
  font-weight  : 600 !important;
  border-radius: 4px !important;
  right        : 23px !important;
}

.filter-popup .ant-modal-body {
  border-top: 1px solid #ddd;
}

.ant-modal-title {
  line-height: 1 !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

@media (min-width: 1920px) {
  .white-board {
    min-height: 840px;
  }

  .MuiTableContainer-root.tableschedule {
    height: 690px;
  }

  .common-iconbutton button svg {
    font-size: 30px !important;
  }

  .MuiTableContainer-root.tableverient.tableschedule {
    height: 800px !important;
  }

  .tab-content.dna .no-recordfound {
    height: 551px;
  }

  .tab-content.dna .no-recordfound {
    height: 551px;
  }

  .tab-content.dna .no-recordfound {
    height: 551px;
  }
}

@media (max-width: 1200px) {
  .widgets img {
    width : 85% !important;
    height: 110px !important;
  }

  h4 {
    font-size: 16px;
  }

  .widgets-info p {
    line-height: 20px;
  }
}

@media (max-width: 1024px) {

  .home-app-menu {
    width: 35% !important;
  }
}

@media (max-width: 992px) {
  .widgets-info p {
    font-size  : 13px !important;
    line-height: 18px !important;
  }

  .widgets img {
    width : 75% !important;
    height: 80px !important;
  }

  h4 {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .widgets-box .widgets {
    width : 100% !important;
    height: auto !important;
  }

  .widgets img {
    width : 100% !important;
    height: 120px !important;
  }

  h4 {
    font-size: 16px;
  }

  .widgets-info p {
    font-size  : 14px !important;
    line-height: 20px !important;
  }

  .home-app-menu {
    width: 54% !important;
  }
}

/* @media screen and (min-width: 1728px),
(min-height: 1117px) {
  .white-board {
    min-height: 950px;
  }
} */