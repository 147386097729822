.manage-box1 {
  display: inline-block;
  vertical-align: middle;
  width: 47.4%;
  margin-right: 5%;
}
.privilege-box .modal-divider {
  margin-top: 30px;
}
.manage-box label span {
  font-family: inherit;
  font-size: 16px;
  letter-spacing: 0.02rem;
}
.modal-divider {
  color: #637286;
  background: #f8fafc;
  font-weight: 600;
  margin: 20px 0 15px;
  padding: 10px 15px;
  border-top: 1px solid #d5dce6;
  border-bottom: 1px solid #d5dce6;
  text-align: center;
}
.description textarea {
  padding: 0 !important;
}
