.uploadbutn {
    text-align   : right;
    margin-bottom: 20px;
}

.ticketdatatable .p-datatable-wrapper {
    height: 405px;
}

.ticketdatatable .p-column-title {
    font-size: 13.5px;
}

.ticketdatatable .p-sortable-column-icon {
    font-size  : 9px;
    margin-left: 0.3rem !important;
    margin-top : 4px;
}