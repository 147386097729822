.Loadingpage .MuiCircularProgress-root {
  width: 60px !important;
  height: 60px !important;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  color: #fff;
}
