/*black-theme*/
.black-theme,
.black-theme header,
.black-theme .accountuserlatter {
    color     : #fff !important;
    background: #121212 !important;
}

.black-theme .widgets-box .widgets,
.black-theme .menu-responsive,
.black-theme .menu-popover,
.black-theme .MuiPaper-root,
.black-theme .white-board,
.black-theme .logindetails,
.black-theme .themes-box,
.black-theme .p-sidebar,
.black-theme .tableselect select option,
.black-theme .p-datepicker:not(.p-datepicker-inline),
.black-theme .MuiPickersDay-root,
.black-theme .react-grid-item,
.black-theme .p-treetable .p-treetable-thead>tr>th,
.black-theme .p-treetable .p-treetable-tbody>tr,
.black-theme .p-dialog .p-dialog-header,
.black-theme .p-dialog .p-dialog-content,
.black-theme .popup-content .MuiBox-root,
.black-theme .p-picklist .p-picklist-header,
.black-theme .p-picklist .p-picklist-filter-container,
.black-theme .p-picklist .p-picklist-list,
.black-theme .p-dialog .p-dialog-footer,
.black-theme .savefilters,
.black-theme .select-column-box,
.black-theme .AgentStatusList .p-datatable .p-datatable-thead>tr>th,
.black-theme .left-ar,
.black-theme .add-favdbd-part,
.black-theme .cnd-format-filters,
.black-theme .ant-drawer-content,
.black-theme .p-datatable .p-datatable-tbody>tr,
.black-theme .p-paginator,
.black-theme .ant-select-selector,
.black-theme .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.black-theme .ant-select-dropdown,
.black-theme .ant-input-number,
.black-theme .ant-input,
.black-theme .p-paginator .p-paginator-current,
.black-theme .p-paginator .p-paginator-first,
.black-theme .p-paginator .p-paginator-prev,
.black-theme .p-paginator .p-paginator-next,
.black-theme .p-paginator .p-paginator-last,
.black-theme .ant-popover .ant-popover-inner,
.black-theme .p-multiselect,
.black-theme .p-multiselect-panel .p-multiselect-header,
.black-theme .p-dropdown-panel .p-dropdown-header,
.black-theme .p-multiselect-panel,
.black-theme .ant-picker-panel-container,
.black-theme .ant-modal-content,
.black-theme .ant-modal-header,
.black-theme .search-widget-box {
    background: #1e1e1e;
    color     : #fff !important;
}

.black-theme .breadcrumbox {
    color     : #fff;
    background: #64b5f6;
}

.black-theme h1,
.black-theme h2,
.black-theme h3,
.black-theme h4,
.black-theme h5,
.black-theme .megamenu-box ul li a:hover,
.black-theme .megamenu-box ul li a:active,
.black-theme .megamenu-box ul li a:focus,
.black-theme .tableschedule tbody tr td a,
.black-theme .userdetailsbox p,
.black-theme .menu-trigger i,
.black-theme .header-things svg.MuiSvgIcon-root,
.black-theme .clearfilter:hover,
.black-theme .select-column-box ul li:hover .MuiFormControlLabel-label,
.black-theme .savefilters ul li:hover,
.black-theme .cnd-format-filters ul li:hover,
.black-theme .stepper svg.Mui-active,
.black-theme .filter-box p span,
.black-theme .dropdown-header,
.black-theme .p-treetable .p-sortable-column.p-highlight,
.black-theme .p-datatable .p-datatable-tbody>tr>td .action-buttons a:hover svg {
    color: #64b5f6;
}

.black-theme .widgets-info p,
.black-theme .megamenu-box ul li a,
.black-theme .bread-crums-box ol li a,
.black-theme .bread-crums-box ol li a svg,
.black-theme .bread-crums-box ol li p,
.black-theme .MuiBreadcrumbs-separator,
.black-theme .tableschedule tbody tr td,
.black-theme .userdetailsbox span,
.black-theme .themes-text,
.black-theme .accountmenu ul li,
.black-theme .MuiTablePagination-selectLabel,
.black-theme .MuiInputBase-root,
.black-theme .MuiTablePagination-displayedRows,
.black-theme .MuiSvgIcon-root,
.black-theme .MuiChip-root,
.black-theme .MuiTypography-root,
.black-theme .privilege-text ul li span.MuiFormControlLabel-label,
.black-theme .payrol-content p,
.black-theme .pay-overtime-content p,
.black-theme .widget-title,
.black-theme .widgetheader p,
.black-theme .MuiStepLabel-label,
.black-theme .table-box-widget ul li h4,
.black-theme .p-picklist .p-picklist-list .p-picklist-item,
.black-theme .savefilters ul li,
.black-theme .coloumbox,
.black-theme .filter-box p.defaultt,
.black-theme .cnd-format-filters ul li,
.black-theme .dropdown-item,
.black-theme .ant-drawer-title,
.black-theme .ant-drawer .ant-drawer-close,
.black-theme .ant-form-item .ant-form-item-label>label,
.black-theme .ant-form,
.black-theme .ant-select-dropdown .ant-select-item,
.black-theme .ant-input-number .ant-input-number-input,
.black-theme .a_text,
.black-theme .userlist-box,
.black-theme .remove.removeStyle:hover svg,
.black-theme .ant-input-number-handler-up-inner,
.black-theme .ant-input-number-handler-down-inner,
.black-theme .wfhuser-fullname,
.black-theme .userlist-sharebox,
.black-theme .ant-select-single.ant-select-open .ant-select-selection-item,
.black-theme .ant-input-number-group .ant-input-number-group-addon,
.black-theme .ant-color-picker .ant-color-picker-panel .ant-color-picker-input-container .ant-color-picker-input .ant-color-picker-hex-input.ant-input-affix-wrapper .ant-input-prefix,
.black-theme .domains-dd p,
.black-theme .selectdata-part p,
.black-theme .layout-content .content-section.implementation,
.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.black-theme .p-datepicker .p-datepicker-header,
.black-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message,
.black-theme .MuiAutocomplete-noOptions,
.black-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.black-theme .p-sidebar-close-icon.pi-times,
.black-theme .cardusername,
.black-theme .p-tabview .p-tabview-nav li .p-tabview-nav-link,
.black-theme .ant-collapse-header-text,
.black-theme .anticon svg,
.black-theme .ant-picker-input input,
.black-theme .ant-picker-dropdown .ant-picker-content th,
.black-theme .ant-picker-dropdown .ant-picker-cell-in-view,
.black-theme .ant-picker-dropdown .ant-picker-header,
.black-theme .ant-modal-title,
.black-theme .carousel-box span {
    color: #fff;
}


.black-theme .table-box-widget ul li p,
.black-theme .ant-select .ant-select-selection-placeholder,
.black-theme .p-multiselect .p-multiselect-label.p-placeholder,
.black-theme .p-dropdown .p-dropdown-label.p-placeholder {
    color: #bbb;
}

.black-theme .viewicon-box {
    background: none;
    border    : 2px solid rgba(255, 255, 255, 0.2);
}

.black-theme button.MuiButton-containedSecondary,
.black-theme .sharefooter button.MuiButton-containedSecondary {
    color: #000 !important;
}

.black-theme .viewicon-box.active,
.black-theme .viewicon-box:hover {
    border: 2px solid #64b5f6;
}

.black-theme .accountuserlatter,
.black-theme .flip-container.active,
.black-theme .flip-container:hover,
.black-theme .left-ar:hover {
    border: 1px solid #64b5f6;
}

.black-theme ul.megamenu-submenu {
    background-color: rgba(46, 46, 46, 0.5);
}

.black-theme .menutitle,
.black-theme .common-iconbutton Button,
.black-theme .upload-time-box .uploadbutn>span,
.black-theme .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.black-theme .MuiSwitch-track,
.black-theme .MuiPickersDay-today,
.black-theme .addnewbutn button,
.black-theme .add-favdbd-part ul li.selected_dsb .favdbd-name,
.black-theme .add-favdbd-part ul li:hover .favdbd-name,
.black-theme .addcondition-butn,
.black-theme Button,
.black-theme .MuiButton-root.MuiButtonBase-root,
.black-theme .alert-butn {
    background-color: #64b5f6;
    color           : #fff !important;
}


.black-theme .widgets-box .widgets:hover {
    box-shadow: 5px 10px 0 rgb(169 184 200 / 33%);
}

.black-theme .tableschedule thead tr th,
.black-theme .p-datatable .p-datatable-thead>tr>th {
    color        : #fff !important;
    background   : #2e2e2e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-top   : 1px solid rgba(255, 255, 255, 0.5);
}

.black-theme .searchinput,
.black-theme .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(255, 255, 255, 0.6);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.black-theme .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(255, 255, 255, 1);
}

.black-theme .tableschedule tbody tr td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.black-theme .MuiInputLabel-root.MuiInputLabel-formControl {
    color: #ddd;
}

.black-theme .p-sidebar .p-sidebar-header {
    border-bottom: 1px solid #ddd;
}

.p-sidebar .p-sidebar-header {
    padding: 10px 1.25rem !important;
}

.black-theme .privilege-text ul li:hover,
.black-theme .privilege-text ul.nested.active li:hover {
    background-color: rgba(255, 255, 255, 0.03);
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #64b5f6;
}

.black-theme .MuiDialog-root .MuiDialogContent-root {
    border-bottom: 1px solid #ddd;
    border-top   : 1px solid #ddd;
}

.black-theme .MuiButton-containedError {
    background-color: #d32f2f !important;
    color           : #fff;
}

.black-theme .MuiDialog-paper .MuiIconButton-root,
.black-theme .MuiAutocomplete-clearIndicator {
    background-color: inherit !important;
}

.black-theme .tableselect select,
.black-theme .MuiTabs-root button {
    background: inherit !important;
    color     : #fff !important;
}

.black-theme .p-inputtext,
.black-theme .p-dropdown,
.black-theme .ant-input,
.black-theme .ant-picker {
    background  : #1e1e1e;
    color       : #fff !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.black-theme .p-dropdown .p-dropdown-trigger {
    color: rgba(255, 255, 255, 0.6);
}

.black-theme .p-dropdown-panel {
    background: #1e1e1e;
    color     : rgba(255, 255, 255, 0.87);
    border    : 1px solid #383838;
}

.black-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: rgba(255, 255, 255, 0.87);
}

.black-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color     : rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.03);
}

.black-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color     : rgba(255, 255, 255, 0.87);
    background: rgba(100, 181, 246, 0.16);
}

.black-theme .MuiSwitch-root .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
    background-color: #64b5f6;
}

.black-theme .disable-buttn a svg,
.black-theme .disable-buttn a {
    color : #78909c;
    cursor: unset;
}

.black-theme .MuiStepIcon-text {
    fill: #000;
}

.black-theme .css-boketc-MuiSvgIcon-root,
.black-theme .clearfilter {
    color: rgb(239, 83, 80);
}

.black-theme .flip-container {
    border: 1px solid rgba(25, 118, 210, 0.2);
}

.black-theme .MuiButtonBase-root.Mui-disabled {
    color           : rgba(0, 0, 0, 0.5);
    background-color: #808080;
}

.black-theme .table-box-widget ul li {
    border: 1px solid rgba(25, 118, 210, 0.2);
}

.black-theme .table-box-widget ul li.widgetactive:before {
    background-color: #64b5f6;
}

.black-theme .AgentStatusList .p-datatable .p-datatable-tbody>tr.p-rowgroup-header>td {
    background: #333;
}

.black-theme .disable-buttn svg {
    color: #999;
}

.black-theme .left-ar {
    border: 1px solid transparent;
}

.black-theme .filter-box p,
.black-theme .tzone span,
.black-theme .dropdowndescription {
    color: #aaa;
}

.black-theme .p-row-odd {
    background: rgba(255, 255, 255, 0.93) !important;
}

.black-theme .removeStyle:hover svg {
    color: #000;
}

.black-theme .stepper svg.Mui-active {
    color: #64b5f6 !important;
}

.black-theme .highcharts-background {
    fill: #1e1e1e;
}

.black-theme .highcharts-text-outline {
    stroke      : inherit !important;
    stroke-width: 0px !important;
}

.black-theme .highcharts-label text {
    fill       : #fff !important;
    color      : #fff !important;
    font-weight: normal !important;
}

.black-theme .highcharts-label text tspan {
    font-weight: normal !important;
}

.highcharts-container {
    margin: 0 auto;
}

.black-theme .selctingbox,
.black-theme .dropdown {
    background: none;
}

.black-theme .dropdown-body {
    border-top: none;
}

.black-theme .dropdown-.open {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
}

.black-theme .p-datatable .p-datatable-tbody>tr.p-row-odd {
    background: rgba(255, 255, 255, 0.01) !important;
    color     : #fff !important;
}

.black-theme .p-datatable .p-datatable-tbody>tr>td {
    border       : 0;
    border-bottom: 1px solid #383838;
}

.privilege-text>ul {
    margin-bottom: 0;
}

.black-theme .common-iconbutton Button {
    background-color: #64b5f6 !important;
}

.black-theme .formatdivider,
.black-theme .modal-divider,
.black-theme .userAccesstable th {
    background-color: #2e2e2e;
    color           : #fff !important;
    border-top      : 1px solid rgba(255, 255, 255, 0.3);
    border-bottom   : 1px solid rgba(255, 255, 255, 0.3);
}

.black-theme .colorbox,
.black-theme .colorpicker-box,
.black-theme .droparrowside,
.black-theme .droparrowside button {
    background: #333 !important;
}

.black-theme .ant-btn-default:hover {
    background-color: #ddd !important;
    color           : #000 !important;
}

.black-theme .colorpicker-box {
    border: none;
}

.black-theme .p-datatable .p-datatable-thead>tr>th:hover,
.black-theme .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #252525 !important;
    color     : rgba(255, 255, 255, 0.87) !important;
}

.MuiTablePagination-toolbar p {
    margin-bottom: 0 !important;
}

.black-theme .p-paginator .p-paginator-current,
.black-theme .p-paginator .p-paginator-first,
.black-theme .p-paginator .p-paginator-prev,
.black-theme .p-paginator .p-paginator-next,
.black-theme .p-paginator .p-paginator-last {
    border: none;
}

.black-theme .p-paginator button:hover {
    background: rgba(255, 255, 255, 0.03) !important;
    cursor    : pointer;
}

.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
    background-color: inherit !important;
}

.black-theme .widgets-butns.widgets-bg button,
.black-theme .p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: inherit !important;
}

.black-theme .widgets-bg button.addwidgetbutn:hover {
    border-bottom: 1px solid #64b5f6;
}

.black-theme .statusFilter-edit:hover,
.black-theme .statusFilter-apps:hover,
.black-theme .xHourSearch-apps:hover,
.black-theme .AgentStatusFilter:hover,
.black-theme .cndnal_format-app:hover,
.black-theme .removeStyle:hover,
.black-theme .search-widget:hover {
    background: #333;
}

.black-theme .p-datatable .p-sortable-column.p-highlight {
    color: #64b5f6 !important;
}

.black-theme .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    color                  : #777;
    -webkit-text-fill-color: #777;
    -moz-text-fill-color   : #777;
    -ms-text-fill-color    : #777;
}

.black-theme .MuiOutlinedInput-root.Mui-disabled fieldset {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.tab-content.dna .no-recordfound {
    height         : 342px;
    background-size: 15%;
}

.black-theme .ant-input-number-handler-wrap {
    background: #333 !important;
}

.black-theme .wfh-userdetail Button:hover {
    background-color: inherit !important;
    color           : #fff;
}

.black-theme .react-grid-item>.react-resizable-handle::after {
    border-right : 2px solid rgba(255, 255, 255, 1);
    border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.black-theme .ant-select .ant-select-arrow {
    color: rgba(255, 255, 255, 0.5);
}

.black-theme .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.04);
}

.black-theme .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color           : rgba(0, 0, 0, 0.88) !important;
    font-weight     : 600;
    background-color: #e6f4ff;
}

.black-theme .droparrowside button:hover {
    background-color: inherit !important;
}

.black-theme .droparrowside button {
    color: rgba(255, 255, 255, 0.5) !important;
}

.black-theme .ant-input-affix-wrapper {
    background-color: #2e2e2e;
}

.black-theme .highcharts-tooltip text {
    fill: #000 !important;
}

.black-theme .p-datepicker {
    background: #1e1e1e;
    border    : 1px solid #383838;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.black-theme .p-datepicker table td>span.p-highlight {
    color     : rgba(255, 255, 255, 0.87);
    background: rgba(100, 181, 246, 0.16);
}

.black-theme .p-datepicker .p-monthpicker .p-monthpicker-month {
    background: none;
    color     : #fff;
}

.black-theme .p-datepicker .p-monthpicker .p-monthpicker-month:hover,
.black-theme .p-datepicker .p-yearpicker .p-yearpicker-year:hover,
.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:hover,
.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:hover {
    background: rgba(255, 255, 255, 0.03) !important;
}

.black-theme .common-iconbutton Button.secondary {
    background-color: #383838 !important;
    color           : #fff !important;
}

.black-theme .common-iconbutton Button.secondary:hover {
    background-color: #333 !important;
    color           : #fff !important;
}

.black-theme Button:hover,
.black-theme .upload-time-box .uploadbutn>span:hover,
.black-theme .addcondition-butn:hover,
.black-theme .alert-butn:hover {
    background-color: #43a5f4 !important;
    color           : #fff !important;
}

.black-theme .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: none !important;
    color     : #64b5f6 !important;
    border    : inherit;
}

.black-theme .MuiTabs-vertical {
    border-color: #383838;
}

.black-theme .filter-box {
    background-color: #383838 !important;
}


.p-button.p-confirm-dialog-reject,
.black-theme .p-button.p-confirm-dialog-reject {
    background: none !important;
}

.black-theme .p-button,
.black-theme .p-picklist .p-picklist-list .p-picklist-item.p-highlight,
.black-theme .ant-btn-primary {
    color     : #fff !important;
    background: #64b5f6 !important;
}

.black-theme .searchbutton-icn {
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.black-theme .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: inherit;
    border          : none;
}

.p-paginator-bottom .p-dropdown .p-dropdown-label.p-inputtext {
    padding: 0.3rem 0.75rem 0.55rem !important;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button,
.p-fileupload-choose.p-button-danger,
.black-theme .p-button.p-button-danger {
    color     : #ffffff;
    background: #dc3545 !important;
    border    : 1px solid #dc3545;
}

.p-confirm-dialog-reject {
    background-color: inherit !important;
    color           : #000 !important;
}

.black-theme .p-confirm-dialog-reject {
    color           : #fff !important;
    background-color: inherit !important;
}

.black-theme .p-checkbox-box:hover {
    border-color: #64b5f6;
}

.black-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover {
    color     : rgba(255, 255, 255, 1) !important;
    background: rgba(255, 255, 255, 0.03) !important;
}

.black-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: rgba(100, 181, 246, 0.16);
}

.black-theme .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #64b5f6;
    background  : #64b5f6;
}

.black-theme .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #212529;
}

.black-theme .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    color: rgba(255, 255, 255, 0.6);
}

.black-theme .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color       : rgba(255, 255, 255, 0.87);
    border-color: transparent;
    background  : rgba(255, 255, 255, 0.03);
}

.black-theme .p-dropdown-panel .p-dropdown-header {
    border-bottom: none;
}

.black-theme .p-paginator .p-paginator-pages .p-paginator-page:hover {
    color: #64b5f6
}

.black-theme .p-sidebar-close:hover {
    color: #fff !important;
}

.black-theme .MuiAutocomplete-paper ul li:hover,
.black-theme .MuiMenu-paper ul li:hover,
.black-theme .MuiAutocomplete-popper .MuiAutocomplete-option:hover,
.black-theme .select-column-box ul li:hover,
.black-theme .savefilters ul li.personal-filter-option ul li:hover,
.black-theme .savefilters ul li:hover,
.black-theme .cnd-format-filters ul li:hover {
    background: rgba(255, 255, 255, 0.03) !important;
}

.black-theme .p-radiobutton .p-radiobutton-box,
.black-theme .p-checkbox .p-checkbox-box {
    border    : 2px solid #383838;
    background: #121212;
}

.black-theme .MuiCalendarPicker-root button {
    background: none !important;
    color     : #fff !important;
}

.black-theme .selectdata-part {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.black-theme button.p-button-secondary {
    background-color: #383838 !important;
    color           : #fff !important;
    border          : 1px solid rgba(255, 255, 255, 0.05) !important;
}

.black-theme .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background  : #1e1e1e;
    border-color: #64B5F6;
    color       : #64B5F6;
}

.black-theme .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background  : none;
    border-color: inherit;
    border-width: 0;
}

.black-theme .p-tabview ul li.p-tabview-selected.p-highlight .p-tabview-nav-link {

    border-color: #64B5F6 !important;
    color       : #64B5F6 !important;
    border-width: 0 0 2px 0;
}

.black-theme .p-tabview .p-tabview-panels {
    background: none;
}

.black-theme .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background  : none;
    border-color: #64B5F6;
    color       : #64B5F6;
}

.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.black-theme .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    background: none !important;
}

.black-theme .alerts-card {
    background   : #1e1e1e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

}

.black-theme .mark-butn button {
    color     : #ddd !important;
    background: none !important;
}

.black-theme .mark-butn button:hover {
    color: #fff !important;

}

.black-theme .cardsupdate {
    color: #bbb;
}

.black-theme .cardsdate {
    color     : #fff;
    background: #333;
}

.black-theme .cardsbutn.acceptbutn svg {
    color: #64B5F6 !important;
}

.black-theme .cardsbutn.acceptbutn:hover svg {
    color: #fff !important;
}

.black-theme .cardsbutn.declinebutn svg {
    color: #ef4444 !important;
}

.black-theme .cardsbutn.declinebutn:hover svg {
    color: #fff !important;
}

.black-theme .attributes-box .ant-collapse,
.black-theme .ant-picker {
    background-color: #1e1e1e;
    color           : #fff;
}

.black-theme .attributes-box button.ant-btn {
    background-color: #1e1e1e !important;
    color           : #fff !important;
}

.black-theme .attributes-box button.ant-btn:hover {
    color: #64b5f6 !important;
}

.black-theme .attributes-box button.ant-btn:hover .ant-btn-icon svg {
    color: #64b5f6 !important;
}

.black-theme .ant-drawer .ant-drawer-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.black-theme .ant-collapse-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.black-theme .attributes-box .footerbutn button.ant-btn-primary {
    background: #64b5f6 !important;
}

.black-theme .attributes-box .footerbutn button.ant-btn-primary:hover {
    color           : #fff !important;
    background-color: #43a5f4 !important;
}

.black-theme .filter-popup .ant-modal-body {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.black-theme .adv-filterbox {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.black-theme .p-inputswitch .p-inputswitch-slider {
    background: #383838 !important;
}

.black-theme .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #64b5f6 !important;
}

.black-theme .sla-mainbox #service-level-undefined .text-group text {
    fill: rgb(255, 255, 255) !important;
}

.black-theme .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: rgba(255, 255, 255, 0.2);
}

.black-theme .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #64b5f6 !important;
}

.black-theme button.p-carousel-next:hover,
.black-theme button.p-carousel-prev:hover {
    background-color: inherit !important;
}

.black-theme .p-carousel .p-carousel-content .p-carousel-prev:focus,
.black-theme .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: none !important
}

.black-theme .carousel-box p,
.black-theme .sla-text {
    color: #64b5f6;
}

.black-theme .sla-mainbox {
    background: rgba(0, 0, 0, 0.3);
}

.black-theme .norecordtext {
    color: darkgray;
}

.black-theme .headcount-widgets ul li {
    border    : 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 3px rgba(255, 255, 255, 0.1);
}

.black-theme .react-grid-item,
.black-theme .MuiPaper-root.MuiDialog-paper {
    background: #1e1e1e !important;
}