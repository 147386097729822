.popup-content #vertical-tabpanel-0,
.popup-content #vertical-tabpanel-1,
.popup-content #vertical-tabpanel-2,
.popup-content #vertical-tabpanel-3,
.popup-content #vertical-tabpanel-4,
.popup-content #vertical-tabpanel-5 {
  width: 84%;
}

.popup-content #vertical-tabpanel-0 .MuiBox-root,
.popup-content #vertical-tabpanel-1 .MuiBox-root,
.popup-content #vertical-tabpanel-2 .MuiBox-root,
.popup-content #vertical-tabpanel-3 .MuiBox-root,
.popup-content #vertical-tabpanel-4 .MuiBox-root,
.popup-content #vertical-tabpanel-5 .MuiBox-root {
  padding: 10px 00px 10px 30px;
}

.popup-content .MuiBox-root .p-multiselect {
  width: 100%;
}

.popup-content .MuiTabs-root button {
  align-items        : flex-start !important;
  -webkit-align-items: flex-start !important;
}

/* .popup-content .MuiTabs-root {
  width: 20%;
} */

.picklist-filter .card .p-picklist .p-picklist-filter-container {
  padding: 10px !important;
}

.filter-box {
  padding         : 15px;
  background-color: #f2f2f2;
  box-shadow      : 0 1px 2px rgb(0 0 0 / 20%);
  margin-top      : 30px;
  margin-bottom   : 20px;
}

.filter-box p {
  margin        : 0 5px;
  padding       : 0;
  display       : inline-block;
  vertical-align: middle;
  font-weight   : 400;
  color         : #6a6a6a;
  font-size     : 13.5px;
}

.filter-box p span {
  font-weight: 700;
  color      : #1976d2;
}

.filter-box p.defaultt {
  font-weight: 500;
  color      : #000;
}