.admin-lobbuttn {
    text-align: right;
}

.tab-master {
    border-bottom: 1px solid #ddd;
}

.tab-master .MuiTabs-root .MuiButtonBase-root.MuiTab-root {
    font-size: 14px !important;
    font-weight: bold !important;
}

.search-box-common {
    position: relative;
    width: 400px;
}

.alert-butn {
    border-radius: 3px;
    width: 60px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    padding: 6px 10px 7px !important;
    color: #1976d2 !important;
    position: relative;
    height: 40px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
    background: none !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #ced4da;
}

.alert-butn:hover {
    border-color: #1976d2 !important;
    background-color: #fff !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.alert-butn svg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    transform: translateY(-50%);
    font-size: 28px;
}

.alert-butn span {
    color: #fff;
}

.alert-butn span.p-badge {
    position: absolute;
    top: 4px;
    right: 4px;
    margin: 0 auto;
    width: auto;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.01rem;
    padding: 4px 6px !important;
}

.alertpopup.p-sidebar .p-sidebar-content {
    padding: 15px;
    padding-top: 0;
}

.alert-butn .p-button-loading-icon {
    margin-top: 2px !important;
}

.export-alert-butn {
    margin: 0 15px 0 0;
}
