@import "./../../../../../assests/styles/variables.scss";

.p-slider {

  .p-slider-handle {
    background: $primary-color !important;
    border: 2px solid $primary-color !important;
  }

  .p-slider-range {
    background: $primary-color !important;
  }

}