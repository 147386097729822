.loader {
  margin           : 0 auto;
  text-align       : center;
  position         : absolute;
  left             : 0;
  right            : 0;
  top              : 52%;
  transform        : translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform   : translateY(-50%);
}

.cover-spin {
  position        : fixed;
  width           : 100%;
  left            : 0;
  right           : 0;
  top             : 0;
  bottom          : 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index         : 9999;
}

.loader .MuiCircularProgress-svg {
  color: #fff;
}