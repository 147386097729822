.HCPAlertFilter {
    height        : 40px;
    padding       : 6px 10px;
    cursor        : pointer;
    display       : inline-block;
    vertical-align: middle;
    position      : relative;
    margin-top    : -21px;
    border-radius : 3px;
    width         : auto;
    color         : #1976d2 !important;
    background    : none !important;
    border        : 1px solid transparent;
    border-bottom : 1px solid #ced4da;
    box-shadow    : 0px 2px 3px 0 rgb(0 0 0 / 10%);
    text-align    : center;
    margin-right  : 15px;
  }
  
  .agentstatusfilter-box:hover {
    background: #E9ECEF;
  }
  
  .HCPAlertFilter:hover {
    border-color    : #1976d2;
    background-color: #fff !important;
    box-shadow      : 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  
  
  .HCPAlertFilter img {
    width : 17px;
    height: 17px;
  }
  
  .filtermenu {
    padding: 30px 0;
  }
  
  .filtermenu h5 {
    font-size    : 16px;
    font-weight  : 600;
    margin-bottom: 5px;
  }
  
  .filtermenubox {
    margin-bottom: 30px;
  }
  
  .filtermenubox .p-multiselect {
    width: 100%;
  }
  
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color     : #000 !important;
    background: #E9ECEF !important;
  }
  
  .dialog-demo .p-button {
    margin   : 0 0.5rem 0 0;
    min-width: 10rem;
  }
  
  .dialog-demo p {
    margin     : 0;
    line-height: 1.5;
  }
  
  .dialog-demo .p-dialog .p-button {
    min-width: 6rem;
  }
  
  .popup-content {
    padding: 20px 0px 0;
  }
  
  .popup-content .MuiTabs-vertical {
    width: 18%;
  }
  
  .clearfilter svg {
    vertical-align: middle;
  }
  
  .filter-footer {
    padding: 0 0 20px 0;
    color  : rgba(0, 0, 0, 0.6);
  }
  
  .clearfilter {
    cursor    : pointer;
    float     : left;
    width     : 20%;
    margin-top: 4px;
    font-size : 15px;
    text-align: left;
  }
  
  .clearfilter:hover {
    color: #1976D2;
  }
  
  .buttnsfilter {
    float       : right;
    text-align  : right;
    margin-right: 10px;
  }
  
  .buttnsfilter .common-iconbutton Button {
    padding     : 4px 15px 3px !important;
    margin-right: 15px;
    width       : 90px;
    font-size   : 15px !important;
  }
  
  .buttnsfilter .common-iconbutton Button:last-child {
    margin-right: 0;
  }
  
  .buttnsfilter .common-iconbutton Button.secondary {
    background-color: rgba(0, 0, 0, 0.54) !important;
    color           : #fff !important;
  }
  
  .buttnsfilter .common-iconbutton Button.secondary:hover {
    background-color: rgba(0, 0, 0, 0.64) !important;
  }
  
  .search-widget-box .p-input-icon-left,
  .search-widget-box input {
    width: 100%;
  }
  
  .alertsavefilters {
    text-align: left;
  }
  
  .HCPAlertFilter:hover>.alertsavefilters {
    display: block;
    opacity: 1;
  }
  
  .alertsavefilters ul {
    margin : 0;
    padding: 4px 0;
  }
  
  .alertsavefilters ul li {
    margin        : 0;
    padding       : 3px 10px;
    position      : relative;
    cursor        : pointer;
    font-size     : 14px;
    font-weight   : 500;
    letter-spacing: 0.02rem;
    color         : #000;
    font-family   : "Lato", sans-serif;
  }
  
  .filteredit-deletebutn {
    display : none;
    position: absolute;
    right   : 0px;
    top     : 0;
    z-index : 999999999;
  }
  
  .alertsavefilters ul li:hover .filteredit-deletebutn,
  .alertsavefilters ul li.personal-filter-option ul li:hover .filteredit-deletebutn {
    display: block;
  }
  
  .alertsavefilters ul li:hover {
    color: #1976D2;
  }
  
  .alertsavefilters ul li:hover.personal-filter-option {
    color: unset;
  }
  
  .alertsavefilters ul li.divider {
    border-bottom : 1px solid #ddd;
    padding-bottom: 8px;
  }
  
  .filteredit-deletebutn svg {
    color : #777;
    width : 0.6em !important;
    height: 0.6em !important;
    margin: 6px 2px 0 0 !important;
    cursor: pointer;
  }
  
  .filteredit-deletebutn svg:last-child {
    margin-right: 0 !important;
  }
  
  .filteredit-deletebutn svg:hover {
    color: #1976D2;
  }
  
  .edit-delete-butn {
    color: red;
  }
  
  .footerfiltername {
    margin-top: 30px;
    text-align: right;
  }
  
  .footerfiltername button {
    margin-right: 30px;
  }
  
  .footerfiltername button:last-child {
    margin-right: 0px !important;
  }
  
  .footerfiltername Button.secondary {
    background-color: #CBCBCB !important;
    color           : #000 !important;
    border          : 1px solid transparent;
  }
  
  .footerfiltername Button.secondary:hover {
    border: 1px solid #777;
  }
  
  .p-dialog-content {
    padding: 20px 20px !important;
  }
  
  .alertsavefilters ul li.personal-filter-option,
  .alertsavefilters ul li.personal-filter-option ul {
    margin : 0;
    padding: 0;
  }
  
  .alertsavefilters ul li.personal-filter-option {
    padding: 0;
  }
  
  .alertsavefilters ul li.personal-filter-option.global-filter {
    border-bottom: none;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li {
    margin  : 0;
    padding : 5px 10px;
    position: relative;
    cursor  : pointer;
  }
  
  .alertsavefilters ul li.personal-filter-option:hover .filteredit-deletebutn {
    display: none;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li:hover {
    color: #1976D2;
  }
  
  .alertsavefilters ul li svg {
    display       : inline-block;
    vertical-align: middle;
    width         : 0.7em;
    height        : 0.7em;
    margin-right  : 5px !important;
    color         : #777;
    margin-top    : -2px;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li svg {
    width     : 0.5em;
    height    : 0.5em;
    margin-top: -1px;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li.active {
    background-color: rgba(102, 178, 255, 0.15);
    color           : #1976D2;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li span {
    font-size     : 14px;
    text-overflow : ellipsis;
    text-transform: capitalize;
  }
  
  .HCPAlertFilter:hover {
    background: #e9ecef;
  }
  
  .advance-filter-box {
    text-align  : right;
    position    : absolute;
    margin-right: 10px;
    top         : 15px;
    right       : 47px;
  }
  
  .advance-filter-box .MuiSwitch-root {
    width : 62px;
    height: 41px;
  }
  
  .advance-filter-box .MuiSwitch-root .MuiSwitch-thumb {
    margin: 1.8px 0px 3px 3px;
  }
  
  .advance-filter-box label {
    margin-right: 0;
  }
  
  .names-filterss {
    width  : 100%;
    padding: 20px 0 10px;
    margin : 0 auto;
  }
  
  .names-filterss>div {
    margin-bottom : 0px;
    display       : inline-block;
    vertical-align: middle;
    margin-right  : 20px;
    width         : 25.1%;
  }
  
  button.add-rule-box {
    padding   : 10px !important;
    width     : 100%;
    text-align: center;
    cursor    : pointer;
    background: none !important;
    box-shadow: none !important;
    color     : #1976D2;
  }
  
  button.add-rule-box:hover {
    background  : rgba(99, 102, 241, 0.04) !important;
    color       : #1976D2;
    border-color: transparent;
  }
  
  .adv-filterbox {
    padding      : 0 10px;
    width        : 100%;
    margin       : 20px auto 20px;
    overflow-x   : hidden;
    overflow-y   : auto;
    border-bottom: 1px solid #ddd;
    height       : 340px;
  }
  
  .add-rule-box svg {
    vertical-align: top;
    margin-right  : 3px;
    margin-top    : -2px;
  }
  
  .delete-rule-box {
    color: red !important;
  }
  
  .names-filterss>div.remove-fil-butn {
    display       : inline-block;
    vertical-align: middle;
    margin-right  : 0px;
    width         : auto !important;
    text-align    : center;
  }
  
  .names-filterss>div.andorpart {
    width       : 10%;
    margin-right: 5px;
  }
  
  .addfilterforadvance {
    width : 20%;
    margin: 0 auto;
  }
  
  .names-filterss .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 7px !important;
  }
  
  .names-filterss .MuiInputLabel-root.MuiInputLabel-formControl {
    font-size     : 15px;
    top           : -4px;
    letter-spacing: 0.02rem;
  }
  
  .names-filterss .textbox .MuiOutlinedInput-input {
    padding: 11px 15px !important;
  }
  
  .adv-filterbox .addcondition-butn {
    padding: 3px 3px 2px !important;
    width  : 18% !important;
  }
  
  .adv-filterbox .addcondition-butn span.ant-btn-icon {
    margin-inline-end: 5px !important;
  }
  
  .footerfiltername button {
    margin-right: 20px;
    padding     : 5px 10px !important;
  }
  
  .advance-filter-box .p-inputswitch {
    width : 3rem;
    height: 1.5rem;
  }
  
  .advance-filter-box .p-inputswitch .p-inputswitch-slider:before {
    margin-top   : -8px;
    width        : 1rem;
    height       : 1rem;
    border-radius: 2px;
  }
  
  .alertsavefilters ul li.personal-filter-option ul li .filteredit-deletebutn .edit-delete-butn svg:first-child {
    margin-right: 3px !important;
  }
  
  .deletedialoug .p-dialog-footer button:first-child {
    background: #fff !important;
    color     : #000 !important;
  }
  
  @media (min-width: 1920px) {
    .filter-popup {
      width: 50vw !important;
    }
  
    .names-filterss>div {
      width: 25.2%;
    }
  
    .clearfilter {
      width: 18%;
    }
  }