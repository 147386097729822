.manage-workgrup {
    margin-top   : 50px;
    margin-bottom: 00px;
}

.group.editqueueform .rightsidebutn {
    margin-top: 0 !important;
}

.manage-workgrup .p-form .textbox {
    width: 47% !important;
}

.manage-workgrup .MuiInputLabel-root.MuiInputLabel-formControl{font-size: 14px;}
.description{margin-bottom: 30px;}