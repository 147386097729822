.p-form .textbox {
  display: inline-block;
  vertical-align: top;
  width: 47.5%;
  margin-bottom: 30px;
  margin-right: 5%;
}
.p-form .p-field.textbox {
  padding-left: 0 !important;
}
.p-form .textbox:nth-of-type(2n) {
  margin-right: 0;
}
